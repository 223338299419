import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className='about-container'>
      <img
        className='about-image'
        src='http://placekitten.com/g/200/200'
        alt='bearded man'
      />
      <div>
        Spicy jalapeno bacon ipsum dolor amet excepteur landjaeger cupidatat,
        exercitation nulla ex ullamco commodo ham shankle porchetta nostrud
        corned beef. Kevin sed andouille pig t-bone tri-tip duis. Picanha jowl
        kielbasa strip steak doner id jerky non ad in ex frankfurter. Nostrud
        biltong doner ea dolore, do leberkas meatball t-bone swine kielbasa enim
        sausage dolor bresaola. Qui turducken aliqua lorem drumstick beef ribs
        kevin voluptate. Ea cow filet mignon turkey meatball beef ribs drumstick
        corned beef ham ad dolore ut pig. Eiusmod pork loin short loin, tongue
        pariatur culpa aliqua laborum ground round ribeye. Non rump aliquip pork
        landjaeger laboris. Pig voluptate strip steak, ea tail nostrud bresaola
        t-bone venison frankfurter in nisi. Mollit prosciutto fatback buffalo,
        incididunt tongue laborum. Veniam excepteur proident aliqua.
      </div>
    </div>
  );
};

export default About;
